import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import Social from "./Social";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import "./Pagina404.css";

const Pagina404 = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Pagina 404 | TakeBy</title>
      </Helmet>

      <div className="page-container">
        <div className="background-image">
          <div className="content-container">
            <img
              src="https://cdn.takeby.it/light-logo-take-by.svg"
              alt="Logo"
              className="logo-image"
            />
            <div className="text-container">
              <h1 className="main-text">Sembra che non abbiamo trovato quello che cercavi!</h1>
              <p className="sub-text">
                Crediamo che avere un'applicazione piena di funzionalità sia
                importante, ma anche che sia stabile e sicura.
              </p>
            </div>
            <div className="button-row">
              <button className="back-button" onClick={() => navigate(-1)}>
                <AiOutlineArrowLeft size={20} style={{ marginRight: '10px' }} />
                Indietro
              </button>
            </div>
            <Social />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Pagina404;
