import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Social from "../components/Social";
//import Negozi from "../negozi/primi";
//import Presentazione from "./presentazione";
import Motivi from "../motivi_esercenti/motivi";
//import Live from "../diretta/live";
import ApplicazioneInfo from "../components/applicazione_info";
import { colors, SPACING } from "../components/libreriaImporta";
import "./HomePage.css";

export default function Principale() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Prenota &amp; Ritira | TakeBy</title>
      </Helmet>

      <div className="main-container">
        <div
          className="background-image"
        >
          <div className="content-container">
            <img
              src="https://cdn.takeby.it/light-logo-take-by.svg"
              alt="Logo"
              className="logo-image"
            />
            <div className="text-container">
              <h1 className="main-text">Negozi a portata di un click</h1>
              <p className="sub-text">Il progetto è in fase di sviluppo</p>
            </div>

            <Social />
          </div>
        </div>
        {/*
        * ApplicazioneInfo è un componente che rappresenta un'interfaccia che indica gli store su cui scaricare l'app
        <ApplicazioneInfo />*/}
        {/*<Presentazione />
        <Live social={false} />
        <Negozi />*/}
        <Footer style={{ marginTop: 30 }} />
      </div>
    </>
  );
}
