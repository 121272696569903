import React from "react";
import { useNavigate } from "react-router-dom";
import { SPACING, colors } from "./libreriaImporta";
import "./Header.css"; // Crea un file CSS separato per gli stili

const Header = () => {
  const navigate = useNavigate();
  const handleOrderButtonClick = () => {
    navigate("/");
  };

  return (
    <div className="header">
      <div className="logoContainer">
        <img
          src={require("../assets/img/background.svg").default} // Assicurati che il percorso sia corretto
          alt="Logo"
          className="logoImage"
        />
      </div>
      <button
        className="orderButton"
        onClick={handleOrderButtonClick}
      >
        Ordina ora
      </button>
    </div>
  );
};

export default Header;
