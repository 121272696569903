import WebFont from "webfontloader";
import { useEffect, useState } from "react";

export const useLoadFonts = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["League Spartan"],
      },
      active: () => setLoaded(true),
    });
  }, []);

  return loaded;
};

export const colors = {
  white: "#fff",
  black: "#000",
  primary: "#1684ac",
  secondary: "#1684acba",
  dark: "rgb(70,70,70)",
  yellow: "#97dceb",
  gray: "rgb(120,120,120)",
  light: "rgb(240,240,240)",
  red: "#ff1616",
};

export const SPACING = 10;
