import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { colors } from "../components/libreriaImporta";

const deviceWidth = window.innerWidth;
const SPACING = 10;

const Motivi = () => {
  const motivi = [
    {
      nome: "Incremento delle prenotazioni",
      descrizione:
        "Il sistema di prenotazione online consente ai clienti di ordinare cibo e altri prodotti in modo facile e veloce, aumentando così le prenotazioni per il commerciante.",
    },
    {
      nome: "Aumento della visibilità online",
      descrizione:
        "Il commerciante avrà la possibilità di essere presente sulla piattaforma Takeby, aumentando la visibilità del proprio negozio e raggiungendo una clientela più ampia.",
    },
    {
      nome: "Valutazione degli utenti",
      descrizione:
        "I clienti potranno valutare il commerciante e lasciare commenti, fornendo così un feedback prezioso per migliorare i propri servizi.",
    },
    {
      nome: "Notifiche push",
      descrizione:
        "I clienti riceveranno notifiche in tempo reale quando la loro prenotazione è pronta, aumentando l'efficienza del servizio.",
    },
    {
      nome: "Nessuna commissione",
      descrizione:
        "Il commerciante non dovrà pagare commissioni per ogni prodotto venduto, come per esempio in altre piattaforme.",
    },
    {
      nome: "Accordo di collaborazione",
      descrizione:
        "Il commerciante firmerà un accordo di collaborazione con il progetto Takeby, garantendo la sicurezza e la collaborazione con noi.",
    },
    {
      nome: "Supporto",
      descrizione:
        "Il commerciante avrà il supporto costante dell'equipe Takeby per qualsiasi necessità, garantendo così un'esperienza di utilizzo della piattaforma senza problemi e una risoluzione tempestiva di eventuali problemi.",
    },
    {
      nome: "Flessibilità",
      descrizione:
        "Il commerciante avrà la possibilità di gestire e modificare il proprio menù e i propri orari di apertura direttamente sulla piattaforma Takeby, in modo da adattarsi alle esigenze dei propri clienti.",
    },
    {
      nome: "Pagamenti sicuri",
      descrizione:
        "Il commerciante avrà la possibilità di accettare pagamenti in modo sicuro e affidabile, tramite la piattaforma Takeby. Gli utenti potranno effettuare il pagamento direttamente in negozio, garantendo così la sicurezza delle transazioni e la tranquillità per entrambe le parti.",
    },
    {
      nome: "Analisi dei dati",
      descrizione:
        "Il commerciante avrà accesso a una serie di dati e statistiche sulle proprie prenotazioni e sui propri clienti, in modo da poter comprendere meglio le proprie performance e adattare la propria offerta in conseguenza.",
    },
  ];

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Scopri perchè dovresti aderire a TakeBy. TakeBy è una piattaforma online che consente di prenotare prodotti e servizi da qualsiasi attività in modo facile e veloce."
        />
      </Helmet>
      <ContainerComplesso>
        <Header />
        <NomeCentrale>Motivi per cui aderire</NomeCentrale>
        <Container>
          {motivi.map((motivo, index) => (
            <Card key={index}>
              <Nome>{motivo.nome}</Nome>
              <Descrizione>{motivo.descrizione}</Descrizione>
            </Card>
          ))}
        </Container>
        <Footer />
      </ContainerComplesso>
    </>
  );
};

const ContainerComplesso = styled.div`
  flex: 1;
  background-color: #fff;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled.div`
  width: ${deviceWidth < 600
    ? `${deviceWidth - SPACING * 4}px`
    : `${(deviceWidth - SPACING * 40) / 3}px`};
  background-color: transparent;
  padding: ${SPACING}px;
  margin: ${SPACING * 2}px;
  border-radius: ${SPACING * 2}px;
  align-items: center;
  gap: ${SPACING}px;
`;

const Nome = styled.h2`
  font-size: ${SPACING * 3}px;
  font-family: "takefont";
  text-align: center;
  font-weight: 700;
  color: ${colors.primary};
`;

const NomeCentrale = styled.h1`
  font-size: ${SPACING * 4}px;
  font-family: "takefont";
  text-align: center;
  margin: ${SPACING * 4}px 0;
  font-weight: 800;
  color: ${colors.primary};
`;

const Descrizione = styled.p`
  font-size: ${SPACING * 2}px;
  font-family: "takefont";
  color: ${colors.secondary};
  text-align: center;
`;

export default Motivi;
