import React from "react";
import styled from "styled-components";
import { FaFacebookF, FaInstagram, FaTwitter, FaTiktok } from "react-icons/fa";
import { SPACING, colors } from "./libreriaImporta";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${SPACING * 2}px;
  padding: ${SPACING * 2}px;
  padding-top: 0;
`;

const LogoCircle = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Social = () => {
  return (
    <Container>
      <LogoCircle onClick={() => window.open("https://www.facebook.com/TakeBy.Official", "_blank")}>
        <FaFacebookF size={32} color={colors.primary} />
      </LogoCircle>
      <LogoCircle onClick={() => window.open("https://www.instagram.com/takeby.official/", "_blank")}>
        <FaInstagram size={32} color={colors.primary} />
      </LogoCircle>
      <LogoCircle onClick={() => window.open("https://twitter.com/TakeBy_it", "_blank")}>
        <FaTwitter size={32} color={colors.primary} />
      </LogoCircle>
      <LogoCircle onClick={() => window.open("https://www.tiktok.com/@takeby.official", "_blank")}>
        <FaTiktok size={32} color={colors.primary} />
      </LogoCircle>
    </Container>
  );
};

export default Social;
