import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from './components/HomePage';
import Pagina404 from './components/404'
import Motivi from './motivi_esercenti/motivi'
//import Negozi from "./pagine/negozi/primi";
//import TipologieDiNegozi from "./pagine/tipologie/tipologie";
import TagManager from "react-gtm-module";

//identify if you are on development or production
//when you build your app process.env.NODE_ENV is set to 'production' 
const env = process.env.NODE_ENV; 

const tagManagerArgs = {
  gtmId: "GTM-KC4L3L8W",
  //the below changes GTM values based on whether dev or production
  auth: env === "development"
      ? "qG_hl3sMfmwj6EAChkpYcQ"
      : "-9vJSnL4hWS95JDC4drdNA",
  preview: env === "development"
    ? "env-7"
    : "env-1"
};

TagManager.initialize(tagManagerArgs)

function App() {
  return (
      <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/motivo/esercenti" element={<Motivi />} />
        {/* <Route path="/negozi" element={<Negozi />} />
            <Route path="/negozi/tipologia" element={<TipologieDiNegozi />} />
            <Route path="/negozi/tipologia" element={<TipologieDiNegozi />} />
        */}
        <Route path="*" element={<Pagina404 />} />
      </Routes>
    </Router>
  );
}

export default App;